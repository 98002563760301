
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageActions } from "@/core/helpers/toolbar";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import WidgetStat1 from "@/components/widgets/WidgetStat1.vue";
import WidgetStat2 from "@/components/widgets/WidgetStat2.vue";
import WidgetStat3 from "@/components/widgets/WidgetStat3.vue";
import moment from "moment/moment";

export default defineComponent({
  name: "admin-dashboard",
  components: {
    WidgetStat3,
    WidgetStat2,
    WidgetStat1,
  },
  setup() {
    const getDateFrom = () => {
      return !store.getters.getDateRangeValue || store.getters.getDateRangeValue.length == 0
        ? ""
        : store.getters.getDateRangeValue[0];
    };

    const getDateTo = () => {
      return !store.getters.getDateRangeValue || store.getters.getDateRangeValue.length < 2
        ? ""
        : store.getters.getDateRangeValue[1];
    };

    const getMutual = () => {
      return store.getters.getFilterMutualValue;
    };

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      setCurrentPageActions(true, true, [], getInitialDateRange());
    });

    const getInitialDateRange = () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
    };

    return {
      store,
      getDateFrom,
      getDateTo,
      getMutual,
    };
  },
});
