import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-10" }
const _hoisted_2 = { class: "col-md-6 col-lg-4 col-xl-4 col-xxl-3" }
const _hoisted_3 = { class: "col-md-6 col-lg-8 col-xl-8 col-xxl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget_stat2 = _resolveComponent("widget-stat2")!
  const _component_widget_stat1 = _resolveComponent("widget-stat1")!
  const _component_widget_stat3 = _resolveComponent("widget-stat3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_widget_stat2, {
        description: "Total Solicitudes de crédito",
        bgColor: "#F1416C",
        bgImage: "/media/patterns/vector-1.png",
        "class-name": "mb-5 mb-xl-10",
        mutual: _ctx.getMutual(),
        date_from: _ctx.getDateFrom(),
        date_to: _ctx.getDateTo()
      }, null, 8, ["mutual", "date_from", "date_to"]),
      _createVNode(_component_widget_stat1, {
        "chart-size": 70,
        mutual: _ctx.getMutual(),
        date_from: _ctx.getDateFrom(),
        date_to: _ctx.getDateTo()
      }, null, 8, ["mutual", "date_from", "date_to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_widget_stat3, {
        mutual: _ctx.getMutual(),
        date_from: _ctx.getDateFrom(),
        date_to: _ctx.getDateTo()
      }, null, 8, ["mutual", "date_from", "date_to"])
    ])
  ]))
}